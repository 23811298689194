<template>
    <div style="background-color: white;">
        <div @click="stopYoutubeVideo()" data-dismiss="modal"
            style="width: 20%;height: 80px;position: fixed;right:0;z-index: 2000;top: 0; ">
        </div>
        <carousel v-if="hasAditional == true" :navigateTo="fistPage" :perPage="1" :autoplay="true" :loop="true"
            :adjustableHeight="true" paginationPosition="bottom-overlay" :paginationColor="bgColorTheme">
            <slide v-if="data.remoteImagePath === null || data.remoteImagePath === undefined">
                <div class="square-box-details " :style="{
                    'background-color': getImgColor(data.indexColor),
                    width: '100%',
                }"></div>
            </slide>
            <slide v-else>
                <div class="square-box-details " :style="{
                    'background-image': `url('${noImg}')`,
                    width: '100%',
                    'background-size': 'cover',
                    'background-position': 'center center',
                }"> <img class="image-inbox-detail" v-lazy="getImageURL(data.remoteImagePath, '400')"
                        @error="setErrorImg($event, remoteImagePath)"></div>
            </slide>
            <slide v-if="remoteVideoPath !== '' && videoSource == 'Uploaded'">
                <video :poster="data.remoteImagePath" controls class="square-box-details " :style="{
                    'width': '100%',
                    'background-size': 'cover',
                    'background-position': 'center center',
                    'aspect-ratio': '1 / 1',
                }">
                    <source :src="remoteVideoPath">
                </video>
            </slide>
            <slide v-if="remoteVideoPath !== '' && videoSource == 'Youtube'">
                <LazyYoutube ref="lazyVideo" :style="{
                    'width': '100%',
                    'background-size': 'cover',
                    'background-position': 'center center',
                    'aspect-ratio': '1 / 1',
                }" :src="remoteVideoPath" />
            </slide>
            <slide v-for="(image, index) in data.additionImagePath" :key="index"
                v-if="image !== null && data.additionImagePath !== undefined">
                <div class="square-box-details " :style="{
                    'background-image': `url('${noImg}')`,
                    width: '100%',
                    'background-size': 'cover',
                    'background-position': 'center center',
                }"> <img class="image-inbox-detail" v-lazy="getImageURL(image, '400')"
                        @error="setErrorImg($event, image)">
                </div>
            </slide>
        </carousel>
        <div v-else class="square-box-details " :style="{
            'background-image': ` url('${noImg}')`,
            width: '100%',
            'background-size': 'cover',
            'background-position': 'center center',
        }"> <img v-if="data.remoteImagePath" class="image-inbox-detail"
                v-lazy="getImageURL(data.remoteImagePath, '400')" @error="setErrorImg($event, remoteImagePath)">
            <img v-else class="image-inbox-detail" :style="{
                'background-color': getImgColor(data.indexColor),

            }">
        </div>
        <div style="padding: 3cap;">
            <table class="mt-3 mb-3">
                <tr>
                    <td>
                        <span class="h3 font-weight-light">
                            {{ data.SKUName }}
                        </span>
                        <h4 class="mt-2 font-weight-light text-color-theme">
                            ฿{{ data.SKUPrice }} / {{ unitName }}
                        </h4>
                        <span class="text-grey" v-html="formatDescription(data.description)"></span>
                    </td>
                </tr>
            </table>
            <div>
                <label class="list-group list-group-flush" v-if="PLULists.length > 1">
                    <h5 class="font-weight-bold">
                        {{ $t('packingSize') }}
                        <small class="text-description" style="font-size: 12px">
                            {{ $t('selectd') }} 1
                            <strong class="text-danger">*</strong>
                        </small>
                    </h5>
                    <label
                        :style="{ 'border-top': 'none', 'border-bottom': index === PLULists.length - 1 ? 'none' : '' }"
                        class="list-group-item pr-0" v-for="(plu, index) in PLULists" :key="'PLU_' + index">
                        <div class="form-check pl-1">
                            <input class="form-check-input accent-color-theme" type="radio" v-model="ProductPLU"
                                :value="plu" />
                            <div class="form-check-label d-flex justify-content-between">
                                <span class="pl-1 text-grey">{{ plu.name }}</span>
                                <span class="text-grey text-right" style="white-space: nowrap;">
                                    {{ PLUPrice(plu.ProductPRU.packUc) }} <br>
                                    {{ getSKURatio(plu) }}
                                </span>
                            </div>
                        </div>
                    </label>
                </label>
                <br />
            </div>


            <div v-for="(option, index) in options" :key="option.objectId">
                <div v-if="option.OptionGroup.require === true">
                    <h5 class="font-weight-bold">
                        {{ option.OptionGroup.name }}
                        <small class="text-description" style="font-size: 12px">
                            {{ $t('selectd') }} {{ option.OptionGroup.maximum }}
                            <strong class="text-danger">*</strong>
                        </small>
                    </h5>
                    <ul class="list-group list-group-flush" v-for="(item, indexItem) in option.items"
                        :key="item.objectId">
                        <label>
                            <li class="list-group-item  pl-1" style="padding-bottom: 15px;">
                                <input :id="`option_${index}_${indexItem}`" type="checkbox" class="accent-color-theme"
                                    v-model="optionItemSelectedIds" :value="getOptionIdWithIndex(index, item.objectId)"
                                    :disabled="optionStatus[index] && !optionItemSelectedIds.includes(`${index}_${item.objectId}`) && option.OptionGroup.maximum !== null && option.OptionGroup.maximum !== undefined"
                                    @change="updatePrice($event, item.price, option, index)" />
                                &ensp; <span class="text-grey">{{ item.name }}</span>
                                <span class="text-grey float-right" v-if="item.price != undefined">
                                    ฿{{ item.price }}
                                </span>
                            </li>
                        </label>
                    </ul>
                </div>

                <div v-else-if="option.OptionGroup.require === false">
                    <h5 class="font-weight-bold">
                        {{ option.OptionGroup.name }}
                        <small class="text-description" style="font-size: 12px">
                            {{ $t('optional') }}
                            <template v-if="option.OptionGroup.maximum">
                                ( {{ $t('maximum') }} {{ option.OptionGroup.maximum }} )
                            </template>
                        </small>
                    </h5>
                    <ul class="list-group list-group-flush" v-for="(item, itemIndex) in option.items" :key="item.id">
                        <label>
                            <li class="list-group-item pl-1">
                                <input :id="`option_${index}_${itemIndex}`" type="checkbox" class="accent-color-theme"
                                    v-model="optionItemSelectedIds"
                                    :disabled="selectLimitExceed(option, index, itemIndex)"
                                    :value="getOptionIdWithIndex(index, item.objectId)"
                                    @change="updatePrice($event, item.price, option, 0)" />
                                &ensp; {{ item.name }}
                                <small class="text-description float-right" v-if="item.price != undefined">
                                    ฿{{ item.price }}
                                </small>
                            </li>
                        </label>
                    </ul>
                </div>
                <br />
            </div>
            <h5 class="text-black mt-3">
                {{ $t('noteToShop') }}
                <span class="text-description" style="font-size: 15px">{{ $t('optional') }}</span>
            </h5>
            <input v-model="note" class="pl-2 form-control pt-0" :placeholder="$t('addYourRequest')"
                style="font-size: 23px;border-radius: 10px;" />
            <table style="width: 100%;" aria-describedby="" class="mt-4">
                <tr>
                    <td style="width: 40%" class="text-right">
                        <button v-if="quantity > 1" class="btn text-color-theme"
                            style="border: 1px solid #b7b7b7; width: 45px;border-radius: 50%;height: 45px;"
                            @click="updateQuantity(-1)">
                            <h3 class="">-</h3>
                        </button>

                        <button v-else-if="quantity <= 1" class="btn  text-color-theme"
                            style="border: 1px solid #b7b7b7; width: 45px;border-radius: 50%; height: 45px;" disabled>
                            <h3 class="">-</h3>
                        </button>
                    </td>
                    <td>
                        <input class="form-control text-center font-weight-bold"
                            style="border: none; font-size: 18px;min-width:30px;" v-model="quantity" pattern="[0-9]*"
                            inputmode="numeric" type="number" min="1" />
                    </td>
                    <td style="width: 40%">
                        <button class="btn text-color-theme"
                            style="border: 1px solid #b7b7b7; width: 45px;border-radius: 50%;height: 45px;"
                            @click="updateQuantity(1)">
                            <h3 class="">+</h3>
                        </button>
                    </td>
                </tr>
            </table>

            <div style="height: 150px;"></div>
        </div>
        <div class="p-3 fixed-bottom"
            style="background-color: white; padding-top:5px;  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);">
            <div v-if="canAddToCart() === true">
                <CButton style="height: 100%;" :disabled="!data.isMenu" class="btn-lg btn-selecteditem  text-center"
                    v-if="quantity > 0" @click="addToCart()" data-dismiss="modal">
                    <span class="text-center" style="font-size: larger;">
                        {{ $t('addToCart') }} • ฿{{ summary }}
                    </span>
                </CButton>
                <CButton class="btn-lg btn-selecteditem " v-else data-dismiss="modal">
                    <span>{{ $t('backToMenu') }}</span>
                </CButton>
            </div>
            <div v-else>
                <CButton class="btn-lg btn-selecteditem " disabled>
                    <span style=" font-size: larger;">
                        {{ $t('addToCart') }}
                    </span>
                </CButton>
            </div>
        </div>

    </div>
</template>

<script>
import poscrm from '@/services/poscrm'
import util from '@/util/util'
import order from '@/util/order'
import '@/util/menu.css'
import { LazyYoutube } from "vue-lazytube";
import { CButton } from '@coreui/vue';
import pos from '@/services/pos'
import { selectLimit } from 'async';

export default {
    props: ['cartTotal', 'SKUObjectId'],
    components: {
        LazyYoutube,
    },
    data() {
        return {
            products: [],
            data: {},
            quantity: 1,
            note: '',
            cartLists: [],
            PLULists: [],
            ProductPLU: {},
            options: [],
            optionSelected: [],
            optionPrice: 0,
            optionItemSelectedIds: [],
            conditionChecker: true,
            optionSorting: [],
            remoteVideoPath: '',
            videoSource: '',
            fistPage: [0, false],
            hasAditional: false,
            optionStatus: [],
            remoteImagePath: '',
        }
    },
    computed: {
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },
        SKURatio() {
            if (this.data.productPLU) {
                return this.data.productPLU.SKURatio || '1'
            } else {
                return '1'
            }
        },
        unitName() {
            if (this.data.unit) {
                return this.data.unit.name
            } else {
                return ''
            }
        },
        categoryName() {
            if (this.data.category) {
                return this.data.category.name
            } else {
                return ''
            }
        },
        getCategoryColor() {
            if (this.data.indexColor !== undefined) {
                return util.generateColor(this.data.indexColor)
            }
        },
        summary() {
            let sum = 0;
            if (this.ProductPLU && this.ProductPLU !== '') {
                if (this.ProductPLU.price !== undefined) {
                    sum = this.ProductPLU.price * this.quantity
                } else if (this.ProductPLU.SKURatio !== undefined && this.data && this.data.SKUPrice !== undefined) {
                    sum = this.ProductPLU.ProductPRU.packUc * this.quantity
                }
            }
            let sumresp = sum + (this.optionPrice || 0) * this.quantity
            return util.convertCurrency(sumresp)
        },
        noImg() {
            return null
        },
        profile() {
            return JSON.parse(localStorage.getItem('profile'))
        },
        OrderType() {
            let type = window.location.pathname;
            if (type === '/delivery/menulists') {
                return 'delivery';
            } else {
                return 'qr';
            }
        },
        bgColorTheme() {
            return $(':root').css('--bg-color-theme') || "#ffffff"
        },
    },
    created() {
        this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []

    },
    methods: {
        ...util,
        setErrorImg(event, image) {
            if (event.target.src == image) {
                event.target.src = this.noImg
            } else {
                event.target.src = image
            }
        },
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        stopYoutubeVideo() {
            if (this.videoSource == 'Youtube') {
                this.$refs["lazyVideo"]['pauseVideo']()
            }
        },
        getCartTotal() {
            let total = this.cartLists.reduce((accum, product) => {
                return accum + product.quantity
            }, 0)
            return total
        },
        PLUPrice(price) {
            if (price === null || price === undefined) {
                return ''
            } else {
                return util.convertNumber(price) + ' THB'
            }
        },
        getImgColor(indexColor) {
            return util.generateColor(indexColor)
        },
        updateQuantity(value) {
            this.quantity = this.quantity + value

            if (this.quantity === 0) {
                this.deleteFromCartList()
            }
        },
        updatePrice(e, price, option, index) {
            if (option.OptionGroup.require == true) {
                this.disableGroups(option, index)
            }

            if (price == undefined) {
                price = 0
            }

            if (e.target.checked === true) {
                this.optionPrice += price
            } else {
                this.optionPrice -= price
            }
        },

        async getProduct() {
            const shopObjectId = this.shopObjectId
            let params = {
                shopObjectId: shopObjectId,
                categoryObjectId: '',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }

            try {
                await poscrm({
                    url: '/api/v1.1/' + this.uid + '/ProductSKU/data/' + this.SKUObjectId,
                    params: params,
                    headers: headers,
                    method: 'GET',
                }).then((res) => {
                    this.data = res.data.data.document
                    this.remoteImagePath = this.data.remoteImagePath

                    if ((this.data.remoteVideoPath !== undefined && this.data.remoteVideoPath !== '') ||
                        (this.data.additionImagePath !== undefined && this.data.additionImagePath.length > 0)) {
                        this.hasAditional = true;
                    } else {
                        this.hasAditional = false;
                    }


                    if (this.data.remoteVideoPath) {
                        this.remoteVideoPath = this.data.remoteVideoPath
                        if (this.remoteVideoPath.startsWith('https://firebasestorage')) {
                            this.videoSource = 'Uploaded'
                        } else {
                            this.videoSource = 'Youtube'
                        }
                    }
                })
            } catch (error) {
                throw new Error(error)
            }

        },
        async getPLUList() {
            let params = {
                skuObjectId: this.SKUObjectId,
                includePLU1: true,
            }
            const headers = {
                shopObjectId: this.shopObjectId,
            }
            try {
                await poscrm({
                    url: '/api/v1.1/' + this.uid + '/productplu/getbysku',
                    params: params,
                    headers: headers,
                    method: 'GET',
                }).then((res) => {
                    this.PLULists = res.data?.data || []
                    if (this.PLULists.length === 1) {
                        this.conditionChecker = true
                        this.ProductPLU = this.PLULists[0]
                    } else {
                        this.conditionChecker = true
                        this.ProductPLU = ''
                        if (this.PLULists.length == 1) {
                            this.ProductPLU = this.PLULists[0]
                        }

                    }

                })
            } catch (error) {
                throw new Error(error)
            }

        },
        getSKURatio(plu) {
            let ratio = ''
            if (plu.SKURatio !== 1) {
                ratio = ` (${plu.unit.name} / ${plu.SKURatio})`
            }
            return ratio
        },
        disableGroups(option, index) {
            if (option.OptionGroup.maximum !== null && option.OptionGroup.maximum !== undefined) {
                let limit = option.OptionGroup.maximum === undefined ? option.items.length : option.OptionGroup.maximum;
                const items = option.items.map(item => `${index}_${item.objectId}`);
                const selected = this.optionItemSelectedIds.filter(itemObjectId => items.includes(`${itemObjectId}`));

                if (selected.length < limit) {
                    this.optionStatus[index] = false
                } else {
                    this.optionStatus[index] = true
                }

                let conditionChecker = true
                this.optionStatus.forEach(element => {
                    if (element == false) {
                        conditionChecker = false
                    }
                });
                this.conditionChecker = conditionChecker
            } else {
                const items = option.items.map(item => `${index}_${item.objectId}`);
                const selected = this.optionItemSelectedIds.filter(itemObjectId => items.includes(`${itemObjectId}`));

                if (selected.length > 0) {
                    this.optionStatus[index] = true
                } else {
                    this.optionStatus[index] = false
                }

                let conditionChecker = true
                this.optionStatus.forEach(element => {
                    if (element == false) {
                        conditionChecker = false
                    }
                });
                this.conditionChecker = conditionChecker

            }
        },


        makeReceiptItem() {
            let SKUData = this.data
            let PLUData = this.ProductPLU
            return order.createReceiptItemsDefault(
                SKUData,
                PLUData,
                this.quantity,
                this.note,
                this.optionSelected
            )
        },
        makeReceiptItemDelivery() {
            let SKUData = this.data
            let PLUData = this.ProductPLU
            return order.createReceiptItemsDelivery(
                SKUData,
                PLUData,
                this.quantity,
                this.note,
                this.optionSelected,
                this.profile
            )

        },
        makeOrderedOption() {
            let resp = {};
            this.optionItemSelectedIds.forEach(itemId => {
                this.options[itemId[0]].items.forEach(optionItem => {
                    if (itemId.split('_')[1] == optionItem.objectId) {
                        resp = {
                            ...this.options[itemId[0]],
                            items: this.options[itemId[0]].items.filter(
                                (item) => item.objectId === itemId.split('_')[1]
                            ),
                        };
                        this.optionSelected.push(resp);
                    }
                })
            })
        },
        async addToDeliveryCart(data) {
            try {
                if (localStorage.getItem('token')) {
                    const uid = localStorage.getItem('uid')
                    const res = await pos({
                        url: `/api/v1.3/${uid}/profile/cart/additem`,
                        method: 'post',
                        data: data,
                    });
                    if (res.data.error.code == 0) {
                        const profile = JSON.parse(localStorage.getItem('profile'))
                        const cart = await pos({
                            url: `/api/v1.3/${uid}/profile/cart?profileObjectId=${profile.objectId}&shopObjectId=${this.shopObjectId}&limit=100`,
                            method: 'get',

                        })
                        this.$emit('update-cartTotal', cart.data.data)
                    } else if (res.data.error.code == 402) {
                        this.renewToken()
                    } else if (res.data.error.code == 4000) {
                        this.logOut()
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        async renewToken() {
            try {
                if (localStorage.getItem('refreshToken')) {
                    const refreshToken = localStorage.getItem('refreshToken')

                    let res = {}
                    res = await pos({
                        url: `/auth/v1.0/renewtoken/otp`,
                        method: 'post',
                        data: {
                            "refreshToken": refreshToken
                        }
                    });
                    if (res.data?.error?.code == 0) {
                        localStorage.setItem('token', res.data?.data?.token)
                        window.location.reload()
                    } else {
                        this.logOut()
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        logOut() {
            const uid = localStorage.getItem('uid')
            const shopObjectId = localStorage.getItem('shopObjectId')
            sessionStorage.clear()
            localStorage.clear()
            localStorage.setItem('uid', uid)
            localStorage.setItem('shopObjectId', shopObjectId)
            this.$router.push('/delivery/home')
        },
        addToCart() {
            this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
            this.trackButtonClick('Detail/AddToCart')
            this.makeOrderedOption()
            let item = {}
            if (this.OrderType == 'delivery') {
                item = this.makeReceiptItemDelivery()
                this.addToDeliveryCart(item)
            } else {
                item = this.makeReceiptItem()
                if (item.orderedOptions !== undefined) {
                    this.cartLists.push(item)
                } else if (item.note !== '') {
                    this.cartLists.push(item)
                }
                else {
                    this.cartLists.push(item)
                }
                sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
                let total = this.getCartTotal()
                this.$emit('update-cartTotal', total)
            }
            this.note = ''
            this.quantity = 1
            this.optionSelected = []
            this.getProduct()
            this.getPLUList()
            this.getOption()
            this.optionPrice = 0
        },
        clearSelected() {
            this.optionItemSelectedIds = []
        },
        deleteFromCartList() {
            let item = this.makeReceiptItem()

            const locationInCart = this.cartLists.findIndex((p) => {
                return p.productPLUId === item.productPLUId
            })

            this.cartLists.splice(locationInCart, 1)
            sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
        },
        async getOption() {
            let params = {
                skuObjectId: this.SKUObjectId,
            }

            try {
                await poscrm({
                    url: '/api/v1.1/' + this.uid + '/productsku/getproductoption',
                    params: params,
                    method: 'GET',
                }).then((res) => {
                    this.clearSelected()
                    this.optionStatus = []
                    this.options = res.data.data

                    if (this.options.length < 1) {
                        this.conditionChecker = true
                    }
                    this.options.forEach((element) => {
                        if (element.OptionGroup && element.OptionGroup.items) {
                            this.sortItems(element);
                        }
                        if (element.OptionGroup.require == true) {
                            this.conditionChecker = false
                        }
                        this.optionStatus.push(!element.OptionGroup.require)

                    });
                })
            } catch (error) {
                throw new Error(error)
            }

        },
        canAddToCart() {
            return this.ProductPLU?.length !== 0 && this.conditionChecker == true;
        },
        sortItems(element) {
            element.OptionGroup.items.sort((a, b) => a.orderIndex - b.orderIndex);
            const orderIndex = []

            element.OptionGroup.items.forEach((element, index) => {
                orderIndex[index] = element.orderIndex
            });
            element.items.forEach((element, index) => {
                element.orderIndex = orderIndex[index]
            });

            element.items.sort((a, b) => a.orderIndex - b.orderIndex);
        },

        formatDescription(description) {
            if (!description) {
                return ''; // Return an empty string if description is null or undefined
            }
            return description.replace(/\n/g, '<br>');
        },
        getOptionIdWithIndex(index, objectId) {
            return `${index}_${objectId}`
        },
        selectLimitExceed(option, index, itemIndex) {
            const items = option.items.map(item => `${index}_${item.objectId}`);
            const selected = this.optionItemSelectedIds.filter(itemObjectId => items.includes(`${itemObjectId}`));
            // Get the ID of the current item
            const currentItemId = `${index}_${option.items[itemIndex].objectId}`;

            // Check if the current item's checkbox is already checked
            if (this.optionItemSelectedIds.includes(currentItemId)) {
                return false; // Return false if the current item's checkbox is checked
            }

            const limit = option.OptionGroup?.maximum || null;
            if (limit !== null) {
                return selected.length >= limit;
            } else {
                return false;
            }
        }
    },
    watch: {
        SKUObjectId(newVal, oldVal) {
            this.getProduct()
            this.getPLUList()
            this.getOption()
            this.optionItemSelectedIds = []
            this.conditionChecker = true
            this.remoteVideoPath = ''
            this.note = ''
            this.videoSource = ''
            this.fistPage = [0, false]
            this.optionPrice = 0
            this.quantity = 1
            this.optionStatus = []
        },
    },
}
</script>

<style scoped>
.form-noborder {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0px;
}

input::placeholder {
    font-size: 15px;
}

footer {
    position: fixed;
    height: 100px;
    bottom: 0;
    width: 100%;
    padding: 30px;
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Add your styles here */
input[type="checkbox"] {
    /* Change the border color to green */
    border-color: var(--bg-color-theme);
    /* Change the background color to green when checked */
}

input[type="checkbox"]:checked {
    background-color: var(--bg-color-theme);
}

.VueCarousel-inner {
    visibility: visible;
}

img.image-inbox-detail {
    position: absolute;
    object-fit: cover;
}

.modal-body {
    padding: 0rem
}

.accent-color-theme {
    accent-color: var(--bg-color-theme);
    transform: scale(1.4);
}
</style>